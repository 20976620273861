import React, { useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { colors, mobileThresholdPixels, Button as ButtonV3, Title2 } from '../../home/v3/styledComponents';
import routesMap from '../../../Routes';
import mask from '../../../assets/covid/mask.svg';

const OpacityContainer = styled.div`
  background: ${colors.opacityFilter};
  overflow: auto;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1050;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  overflow: visible;
  width: 792px;
  margin: 0 auto;
  padding: 60px 90px;
  box-sizing: border-box;

  background-color: ${colors.lightGrey};
  color: ${colors.navy};
  font-family: Roboto;
  font-size: 18px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 90vw;
    padding: 30px;
    margin-top: 100px;
    margin-bottom: 25px;
  }
`;

const Button = styled(ButtonV3)`
  margin: 0px;
  width: 250px;
  ${({ marginTop }) => marginTop && 'margin-top: 5px;'}
  font-size: 18px;
  /* fix text flickering bug */
  transform: scale(1.001);

  @media (max-width: ${mobileThresholdPixels}) {
    min-width: unset;
    width: 100%;
    margin-bottom: 0px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 35px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 15px;
    flex-direction: column;
    align-items: center;
  }
`;

const Paragraph = styled.div`
  text-align: center;
`;

const Image = styled.img`
  margin-top: 35px;
  margin-bottom: 35px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const CovidBanner = () => {
  const [isClosed, setIsClosed] = useState(false);
  if (isClosed) return null;
  return (
    <OpacityContainer onClick={() => setIsClosed(true)}>
      <Container>
        <Title2>Des masques chez vous !</Title2>
        <Image src={mask} alt="Tilly couture" />
        <Paragraph>
          Faites vous livrer des masques en tissus lavables 100% coton, fabriqués par vos couturiers Tillistes.<br />
          <br />
          Nos Tillistes prennent soin de vous.<br />
          🤞 Reprise des RDVs avec votre Tilliste possible le 11 mai 🤞
        </Paragraph>
        <Row>
          <Button navy onClick={() => navigate(routesMap.Masks.url)}>
            Commander
          </Button>
          <Button onClick={() => navigate(routesMap.LaRubrique.url)}>
            La rubrique inspiration
          </Button>
        </Row>
      </Container>
    </OpacityContainer>
  );
};

export default CovidBanner;
