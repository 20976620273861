import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import backgroundDesktopImage from '../../../assets/headers/headerV3.jpg';
import backgroundMobileImage from '../../../assets/headers/headerV3mobile.jpg';
import backgroundCurtainsDesktopImage from '../../../assets/headers/HeaderV3.curtains.jpg';
import backgroundCurtainsMobileImage from '../../../assets/headers/HeaderV3.curtains.mobile.jpg';
import maduraLogo from '../../../assets/home/madureLogo.png';
import { mobileThresholdPixels, fontSizes, Title1, Note, colors } from './styledComponents';
import withAppContext from '../../../withAppContext';
import CTALink from '../../CTALink';

const Background = styled.div`
  width: 100vw;
  min-height: 47vw;
  background: url("${props => props.backgroundImage}") no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -4px;
  ${({ hasBanner }) => hasBanner && 'margin-top: 62px;'}
`;

const BackgroundMobile = styled.div`
  width: 100vw;
  min-height: 47vw;
  background: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    background: url("${props => props.backgroundImage}") no-repeat;
    background-size: cover;
    height: 170vw;
    flex-direction: column-reverse;
    margin-top: 0px;
    ${({ hasBanner }) => hasBanner && 'margin-top: 50px;'}
    ${props => props.negativeMarginBottom && 'margin-bottom: -50px;'}
  }
`;

const StyledTitle1 = styled(Title1)`
  font-size: ${props => props.small ? '36px' : '48px'};
  text-align: ${props => props.textAlign};
  ${props => props.textAlign === 'left' && 'padding-left: 15px'};

  @media (max-width: ${mobileThresholdPixels}) {
    line-height: 30px;
    text-align: center;
    font-size: ${props => props.small ? '20px' : '26px'};
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Content = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  max-width: ${props => props.noMargin ? 650 : 544}px;
  ${props => props.noMargin && 'padding: 0px 0px 0px 20px;'};
  margin-left: 44px;
  box-sizing: border-box;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px;
    margin: 0px 0px 20px;
  }
`;

const Flex1 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: ${mobileThresholdPixels}) {
    justify-content: flex-end;
  }
`;

const StyledDiv = styled.div`
  width: 100%;
`;

const Ul = styled.ul`
  margin-top: 38px;
  margin-bottom: 37px;
  @media (max-width: ${mobileThresholdPixels}) {
    display: none;
  }
`;

const Li = styled.li`
  font-family: Roboto;
  line-height: 28px;
  font-size: ${fontSizes.m};
  margin-bottom: 10px;
  color: ${colors.navy};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 0px;
  }
`;

const CurtainsLogo = styled.img`
  margin-left: 10px;
  width: 140px;
  height: 27px;
`;

const EmptyNote = styled.div`
  @media (max-width: ${mobileThresholdPixels}) {
    height: 50px;
  }

  ${props => props.longTitle && `
    @media (max-width: 350px) {
      height: 25px;
    }
  `}
`;

const ContentRender = ({
  title, hideNote, smallTitle, titleLeft, curtainsNote, prefilledSteps, extraText,
}) => (
  <React.Fragment>
    <Flex1>
      <Content noMargin={smallTitle}>
        <StyledTitle1
          lowercase
          small={smallTitle}
          textAlign={titleLeft ? 'left' : 'center'}
          paddingBottom={!curtainsNote}
        >{title}</StyledTitle1>
        <StyledDiv>
          <Ul>
            <Li>Prise de mesures et livraison chez vous,<br />7 jours sur 7 de 7h à 22h</Li>
            <Li>{curtainsNote
              ? 'Des couturiers professionnels, rapides et efficaces'
              : 'Des couturiers sélectionnés avec soin'}</Li>
            <Li>Les mêmes prix que la retoucherie de quartier</Li>
          </Ul>
        </StyledDiv>
        <CTALink fromInteraction="Header_button" linkState={prefilledSteps} />
        {!hideNote &&
          <Note center>
            à Paris, Marseille, Lyon, Aix-en-Provence et Bordeaux<br />
            {extraText}
          </Note>
        }
        {curtainsNote &&
          <Row>
            <Note>{'service recommandé par '}</Note>
            <CurtainsLogo src={maduraLogo} />
          </Row>
        }
        {hideNote && !curtainsNote && <EmptyNote longTitle={title.length > 72} />}
      </Content>
    </Flex1>
    <Flex1 />
  </React.Fragment>
);

ContentRender.propTypes = {
  title: PropTypes.string,
  hideNote: PropTypes.bool,
  curtainsNote: PropTypes.bool,
  smallTitle: PropTypes.bool,
  titleLeft: PropTypes.bool,
  prefilledSteps: PropTypes.shape({}),
  extraText: PropTypes.string,
};

ContentRender.defaultProps = {
  title: 'Votre service de couturier à domicile',
  hideNote: false,
  curtainsNote: false,
  smallTitle: false,
  titleLeft: false,
  prefilledSteps: {},
  extraText: undefined,
};

// split in 2 renders for loading optimisation => to avoid image flickering on desktop
const Header = ({
  context: { isMobile }, hasBanner, curtainsVersion, backgroundImage, mobileBackgroundImage, ...props
}) => {
  let backgroundImageSrc = '';
  if (curtainsVersion) {
    if (isMobile) {
      backgroundImageSrc = backgroundCurtainsMobileImage;
    } else {
      backgroundImageSrc = backgroundCurtainsDesktopImage;
    }
  } else if (isMobile) {
    backgroundImageSrc = mobileBackgroundImage || backgroundMobileImage;
  } else {
    backgroundImageSrc = backgroundImage || backgroundDesktopImage;
  }

  if (!isMobile) {
    return (
      <Background
        backgroundImage={backgroundImageSrc}
        hasBanner={hasBanner}
      >
        <ContentRender {...props} />
      </Background>
    );
  }
  return (
    <BackgroundMobile
      backgroundImage={backgroundImageSrc}
      negativeMarginBottom={props.hideNote && !props.curtainsNote}
      hasBanner={hasBanner}
    >
      <ContentRender {...props} />
    </BackgroundMobile>
  );
};

Header.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  curtainsVersion: PropTypes.bool,
  hasBanner: PropTypes.bool,
  hideNote: PropTypes.bool,
  curtainsNote: PropTypes.bool,
  backgroundImage: PropTypes.string,
  mobileBackgroundImage: PropTypes.string,
  extraText: PropTypes.string,
};

Header.defaultProps = {
  curtainsVersion: false,
  hasBanner: false,
  hideNote: false,
  curtainsNote: false,
  backgroundImage: undefined,
  mobileBackgroundImage: undefined,
  extraText: undefined,
};

export default withAppContext(Header);
