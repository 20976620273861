import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { UpTitle, Title2, colors, fontSizes, mobileThresholdPixels } from './styledComponents';
import routes from '../../../Routes';
import { toFormattedLinkedText } from '../../../services/formatting';
import retoucheImg from '../../../assets/services/cloth.jpg';
import rideauxImg from '../../../assets/services/curtain.jpg';
import broderieImg from '../../../assets/services/broderie.jpg';
import transformationImg from '../../../assets/services/transfo.jpg';
import creationImg from '../../../assets/services/creation.jpg';

const services = [
  {
    img: retoucheImg,
    imgAlt: 'Retouche de vêtements par une couturière',
    title: 'Retouche de vêtements',
    // eslint-disable-next-line max-len
    text: 'Ajustez la <link>taille de vos vêtements<link>. Nos couturiers prennent vos mesures chez vous et <link>retouchent et réparent tous les textiles<link> à un prix abordable (ourlet à 12 euros).',
    textLinks: ['/retouche-vetement/', '/retouche-vetement/'],
    link: {
      url: routes.Step1.url,
      state: { selectedCategory: 'clothes', selectedTypeOfWork: 'alteration' },
    },
  },
  {
    img: transformationImg,
    title: 'Upcycling',
    imgAlt: 'Une couturière fait une transformation de vêtement',
    // eslint-disable-next-line max-len
    text: 'Un vêtement au fond de l’armoire ou un achat qui manque de peps ?\nGrâce à leur expérience de stylisme, nos couturiers vous conseillent sur les <link>modifications<link> à apporter à vos vêtements pour les rendre tendance.',
    textLinks: ['/la-rubrique/transformation/'],
    link: {
      url: routes.Step1.url,
      state: { selectedTypeOfWork: 'transformation' },
    },
  },
  {
    img: rideauxImg,
    imgAlt: 'Couturières effectuant un ourlet de rideaux',
    title: 'Retouche de rideaux',
    // eslint-disable-next-line max-len
    text: 'Faites facilement <link>retoucher vos rideaux<link> ou voilages en prenant rendez-vous avec un de nos couturiers. Ils se déplacent et vous les livrent directement chez vous.',
    textLinks: ['/retouche-rideaux/'],
    link: {
      url: routes.Step1.url,
      state: { selectedCategory: 'deco', selectedPiece: 'ride', selectedTypeOfWork: 'alteration' },
    },
  },
  {
    img: broderieImg,
    title: 'Broderie',
    imgAlt: 'Une couturière brode du textile',
    // eslint-disable-next-line max-len
    text: 'Personnalisez vos vêtements, accessoires ou linges de maison avec <link>de jolies broderies<link> réalisées à la main par nos couturiers. Initiales, mots ou dessins, ajoutez LE détail qui rendra votre pièce unique.',
    textLinks: ['/broderie/'],
    link: {
      url: routes.Step1.url,
      state: { selectedTypeOfWork: 'embroidery' },
    },
  },
  {
    img: creationImg,
    title: 'Création d’accessoires',
    imgAlt: 'Une couturière brode du textile',
    // eslint-disable-next-line max-len
    text: 'Co-créez avec l’un de nos Tillistes l’accessoire de vos rêves, à partir de chutes de tissus ou d’anciens vêtements. Nos couturiers sauront vous conseiller pour donner vie à toutes vos idées.',
    link: {
      url: routes.Step1.url,
      state: { selectedTypeOfWork: 'creation' },
    },
  },
];

const Container = styled.div`
  margin: 74px 0px 78px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 30px 0px;
  }
`;

const ServicesContainer = styled.div`
  max-width: 1440px;
  margin: 0px 135px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    width: 100vw;
  }
`;

const Service = styled.div`
  margin-bottom: 55px;
  box-sizing: border-box;
  width: ${({ isFirst }) => isFirst ? 100 : 45}%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: ${colors.navy};

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 50px);
    margin: 0px 25px 50px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: ${({ isFirst }) => isFirst ? 463 : 413}px;
  object-fit: cover;

  @media (max-width: ${mobileThresholdPixels}) {
    height: 170px;
    width: 100%;
  }
`;

const Title = styled.h2`
  align-self: flex-start;
  font-family: Libre Baskerville;
  color: ${colors.navy};
  line-height: ${fontSizes.xl};
  font-size: 26px;
  margin-bottom: 14px;
  margin-top: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    margin-bottom: 10px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-right: 45px;
  max-width: 584px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-right: 0px;
  }
`;

const TextSubContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: justify;
  font-family: Roboto;
  color: ${colors.navy};
  line-height: 25px;
  font-size: 14px;
  @media (max-width: ${mobileThresholdPixels}) {
    text-align: left;
  }
`;

const Line = styled.div`
  min-width: 24px;
  height: 1px;
  background-color: ${colors.navy};
  margin: 15px 8px 0px 0px;
`;

const Services = () => (
  <Container>
    <UpTitle>Nos services</UpTitle>
    <Title2 marginBottom>Que peut-on faire pour vous ?</Title2>
    <ServicesContainer>
      {services.map((service, index) => (
        <Service key={service.title} isFirst={index === 0}>
          <Link to={service.link.url} state={service.link.state} style={{ textDecoration: 'none' }}>
            <Image
              src={service.img}
              alt={service.imgAlt}
              isFirst={index === 0}
              loading="lazy"
            />
          </Link>
          <TextContainer>
            <Line />
            <TextSubContainer>
              <Link to={service.link.url} state={service.link.state} style={{ textDecoration: 'none', width: '100%' }}>
                <Title>{service.title}</Title>
              </Link>
              <Text>
                {service.text.includes('<link>')
                  ? toFormattedLinkedText(service.text, service.textLinks, { textDecoration: 'none' })
                  : service.text
                }
              </Text>
            </TextSubContainer>
          </TextContainer>
        </Service>
      ))}
    </ServicesContainer>
  </Container>
);

export default Services;
