import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import routesMap from '../../../Routes';
import { colors, mobileThresholdPixels } from './styledComponents';
import Feedback from './Feedback';
import photoEleonore from '../../../assets/feedbacks/eleonore.png';
import photoDamien from '../../../assets/feedbacks/damien.png';
import photoMarine from '../../../assets/feedbacks/marine.png';
import photoMiguel from '../../../assets/feedbacks/miguel.png';
import photoMaria from '../../../assets/feedbacks/maria.png';
import photoGeoffroy from '../../../assets/feedbacks/geoffroy.png';
import arrow from '../../../assets/feedbacks/arrow.svg';
import withAppContext from '../../../withAppContext';

const Background = styled.div`
  width: 100vw;
  padding: 70px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  background-color: ${colors.navy};

  @media (max-width: ${mobileThresholdPixels}) {
    height: unset;
    padding: 0px;
  }
`;

const FeedbacksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 100vw;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const Arrow = styled.img`
  padding: 45px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 5px 0px 9px;
  }
`;

const TextLink = styled(Link)`
  color: ${colors.white};
  font-size: 14px;
  padding: 20px;
`;

const feedbacks = [
  {
    photo: photoEleonore,
    name: 'Éléonore',
    jobDescription: 'a fait transformer sa robe d’été en chemisier par Khadija',
    feedback: 'Canon et c’est jouissif de transformer des fringues qui hibernaient dans le placard',
    rating: 5,
  },
  {
    photo: photoDamien,
    name: 'Damien',
    jobDescription: 'a fait remailler des pulls par Alexandra',
    // eslint-disable-next-line
    feedback: 'L’expérience utilisateur sur le produit est fluide et claire. Ma couturière était compétente et gentille. Tout s\'est déroulé comme prévu. Super service bravo !',
    rating: 5,
  },
  {
    photo: photoMarine,
    name: 'Marine',
    jobDescription: 'a fait transformer sa tenue de mariage par Chloé',
    // eslint-disable-next-line
    feedback: 'Ce service est vraiment fait pour nous faciliter la vie. J\'ai eu contact avec la couturière Chloé et je n\'ai rien à dire : elle est adorable, à l\'écoute, professionnelle, disponible.',
    rating: 5,
  },
  {
    photo: photoMaria,
    name: 'Maria',
    jobDescription: 'a fait broder un t-shirt par Camille',
    // eslint-disable-next-line
    feedback: 'Brilliant service, pratique, rapide, top professionnal quality, équipe plus que compétente et sympa. Bravo Tilli !!!!! 20/20 !!!',
    rating: 5,
  },
  {
    photo: photoGeoffroy,
    name: 'Geoffrey',
    jobDescription: 'a fait retoucher la longueur de ses rideaux par Alexandra',
    // eslint-disable-next-line
    feedback: 'Excellent service assuré par des personnes professionnelles et compétentes ! Un grand Merci à Alexandra !',
    rating: 4,
  },
  {
    photo: photoMiguel,
    name: 'Miguel',
    jobDescription: 'a fait retoucher ses costumes par Khadija',
    // eslint-disable-next-line
    feedback: 'Le concept est vraiment top et les prix très attractifs comparés aux tailleurs/retouches habituels ! Vive Tilli ;-)',
    rating: 4,
  },
];

class FeedbacksBanner extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpened: false,
    };
  }

  render() {
    const { context: { isMobile } } = this.props;
    const { isOpened } = this.state;
    return (
      <Background>
        <FeedbacksContainer>
          <Feedback isOpened={isOpened} feedback={feedbacks[0]} />
          {(!isMobile || isOpened) &&
            <Feedback isOpened={isOpened} feedback={feedbacks[1]} />}
          {isOpened &&
            <React.Fragment>
              <Feedback isOpened={isOpened} feedback={feedbacks[2]} />
              <Feedback isOpened={isOpened} feedback={feedbacks[3]} />
              <Feedback isOpened={isOpened} feedback={feedbacks[4]} />
              <Feedback isOpened={isOpened} feedback={feedbacks[5]} />
            </React.Fragment>
          }
        </FeedbacksContainer>
        {!isOpened &&
          <Arrow src={arrow} alt="couturière retouchant une robe" onClick={() => this.setState({ isOpened: true })} />
        }
        {isOpened &&
          <TextLink
            to={routesMap.CustomerFeedbacksPage.url}
          >
            Voir plus
          </TextLink>
        }
      </Background>
    );
  }
}

FeedbacksBanner.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(FeedbacksBanner);
