import React from 'react';
import PropTypes from 'prop-types';

import withOrderContext from '../../../withOrderContext';
import { Container, Content } from '../v2/styledComponents';
import CovidBanner from './CovidBanner';
import Header from './Header';
import BenefitsBanner from './BenefitsBanner';
import Services from './Services';
import FeedbacksBanner from './FeedbacksBanner';
import HowItWorks from './HowItWorks';
import MenuBar from '../../../components/home/MenuBar';
import PartnersPager from './PartnersPager';
import Footer from './Footer';

const showCovidBanner = true;

class Home extends React.Component {
  componentDidMount() {
    const { showBannerClacDesDoigts } = this.props;
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    window.onbeforeunload = null;
    if (showBannerClacDesDoigts) {
      const { orderContext: { setPromoCode } } = this.props;
      setPromoCode('CLACDESDOIGTS_17052019');
    }
  }

  render() {
    const { showBannerClacDesDoigts, altTag, headerExtraText } = this.props;
    const hasBanner = showBannerClacDesDoigts || showCovidBanner;
    return (
      <Container>
        <Content full>
          <CovidBanner />
          <MenuBar hasBanner={hasBanner} />
          <Header hasBanner={hasBanner} extraText={headerExtraText} />
          <BenefitsBanner altTag={altTag} />
          <Services />
          <FeedbacksBanner />
          <HowItWorks />
          <PartnersPager />
          <Footer />
        </Content>
      </Container>
    );
  }
}

Home.propTypes = {
  showBannerClacDesDoigts: PropTypes.bool,
  orderContext: PropTypes.shape({
    setPromoCode: PropTypes.func,
  }).isRequired,
  altTag: PropTypes.string,
  headerExtraText: PropTypes.string,
};

Home.defaultProps = {
  showBannerClacDesDoigts: false,
  altTag: null,
  headerExtraText: null,
};

export default withOrderContext(Home);
